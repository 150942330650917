import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { useAuth } from '../../../../contexts/Auth';

import { DELETE_USER } from '../../../../mutations/user';

import DeleteAccount from './component';

const DeleteAccountContainer = ({ modalVisible, cancelModal }) => {
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { user, signOut } = useAuth();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const [deleteUser, { loading }] = useMutation(DELETE_USER, {
    onError(error) {
      message.error(error.error_description || error.message);
    },
    onCompleted() {
      signOut();
      history.push('/signup');
    },
  });

  const deleteUserHandler = () => {
    const email = user.email;

    if (password) {
      deleteUser({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
    } else {
      message.error('Please enter the password!');
    }
  };

  return (
    <DeleteAccount
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      handlePasswordChange={handlePasswordChange}
      onDeleteUser={deleteUserHandler}
      loading={loading}
    />
  );
};

export default DeleteAccountContainer;
