import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { message, Form } from 'antd';

import UserContext from '../../../../contexts/User';
import { HotelContext, ACTIONS } from '../../../../contexts/Hotel';
import { EDIT_FLOOR, DELETE_FLOOR } from '../../../../mutations/floor';
import { errorMessage } from '../errorMessage';

import EditFloor from './component';

const EditFloorContainer = ({ modalVisible, cancelModal, selectedFloor }) => {
  const tenantId = useContext(UserContext);
  const { dispatch } = useContext(HotelContext);
  const [form] = Form.useForm();

  const [updateFloor, { loading: updateLoading }] = useMutation(EDIT_FLOOR, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      message.error(errorMessage);
    },
    onCompleted() {
      form.resetFields();
      cancelModal();
    },
  });

  const [deleteFloor, { loading: deleteLoading }] = useMutation(DELETE_FLOOR, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      message.error(errorMessage);
    },
    onCompleted() {
      form.resetFields();
      cancelModal();
    },
  });

  const updateFloorHandler = ({ updatedFloor }) => {
    updateFloor({
      variables: {
        input: {
          id: selectedFloor.id,
          name: updatedFloor,
        },
      },
    });
  };

  const deleteFloorHandler = () => {
    deleteFloor({
      variables: {
        input: {
          id: selectedFloor.id,
        },
      },
    }).then(({ data }) => {
      if (data.deleteFloor.ok) {
        dispatch({
          type: ACTIONS.DELETE_FLOOR,
          data: selectedFloor,
        });
      }
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedFloor, form]);

  return (
    <EditFloor
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onEditFloor={updateFloorHandler}
      onDeleteFloor={deleteFloorHandler}
      confirmLoading={updateLoading || deleteLoading}
      selectedFloor={selectedFloor}
      form={form}
    />
  );
};

export default EditFloorContainer;
