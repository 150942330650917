import React, { useState } from 'react';
import { useAuth } from '../../contexts/Auth';

import ResetPassword from './component';

const ResetPasswordContainer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const { resetPassword } = useAuth();

  const handleInputChange = () => {
    if (error) {
      setError('');
    }
  };

  const handleResetPassword = async (values) => {
    const { email } = values;

    try {
      setLoading(true);
      const { error } = await resetPassword(email);

      if (!error) {
        setLoading(false);
        setSuccessMessage(true);
      } else throw error;
    } catch (error) {
      setError(error.error_description || error.message);
      setLoading(false);
    }
  };

  return (
    <ResetPassword
      loading={loading}
      handleResetPassword={handleResetPassword}
      successMessage={successMessage}
      handleInputChange={handleInputChange}
      error={error}
    />
  );
};

export default ResetPasswordContainer;
