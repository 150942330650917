import React, { useState, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useAuth } from './contexts/Auth';
import { HotelContext, ACTIONS } from './contexts/Hotel';
import UserContext from './contexts/User';
import { GET_USER_ROLES } from './queries/user';
import { GET_HOTELS_WITH_DATA } from './queries/hotel';

import ProtectedRoute from './ProtectedRoute';
import { Login } from './pages/Login';
import { ResetPassword } from './pages/ResetPassword';
import { ChangePassword } from './pages/ChangePassword';
import { Registration } from './pages/Registration';
import { Dashboard } from './pages/Dashboard';
import { HotelSettings } from './pages/HotelSettings';
import { AccountSettings } from './pages/AccountSettings';
import { CreateHotel } from './pages/CreateHotel';
import { Gateways } from './pages/Gateways';
import Loading from './components/Loading/component';

import 'antd/dist/antd.css';
import { useEffect } from 'react';

const App = () => {
  const { user } = useAuth();
  const { dispatch } = useContext(HotelContext);
  const location = useLocation();
  const isResetPassword = location.hash.includes('type=recovery');
  const [tenantId, setTenantId] = useState('');
  const [loading, setLoading] = useState(true);

  const { data: userRolesData } = useQuery(GET_USER_ROLES, {
    skip: user == null,
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const id = data?.userRoles[0]?.tenantId;
      setTenantId(id);
      console.log('Tenant ID set to:', id);
    }
  });

  const { data: hotelsWithData } = useQuery(GET_HOTELS_WITH_DATA, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    fetchPolicy: 'network-only',
    skip: !tenantId,
  });

  useEffect(() => {
    setLoading(true);

    if (hotelsWithData) {
      dispatch({
        type: ACTIONS.UPDATE_HOTELS_DATA,
        data: hotelsWithData.hotels,
      });
      setLoading(false);
    }
    if (!user) {
      setLoading(false);
    }
  }, [hotelsWithData, dispatch, user]);

  useEffect(() => {
    localStorage.setItem('isEditHotelMode', JSON.stringify({ mode: false }));
  });

  return loading ? (
    <Loading />
  ) : (
    <UserContext.Provider value={tenantId}>
      <Switch>
        <ProtectedRoute path="/settings" component={HotelSettings} />
        <ProtectedRoute path="/account-settings" component={AccountSettings} />
        <ProtectedRoute path="/create-hotel" component={CreateHotel} />
        <ProtectedRoute path="/gateways" component={Gateways} />
        <Route path="/login" component={Login} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/signup" component={Registration} />
        <Route path="/change-password" component={ChangePassword} />
        <Route
          path="/"
          render={(props) =>
            !isResetPassword ? (
              <ProtectedRoute {...props} component={Dashboard} />
            ) : (
              <Redirect to="/change-password" />
            )
          }
        />
      </Switch>
    </UserContext.Provider>
  );
};

export default App;
