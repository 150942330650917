import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { SIGN_UP } from '../../mutations/user';

import Registration from './component';

const RegistrationContainer = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState('');

  const [registerUser, { loading }] = useMutation(SIGN_UP, {
    onError(error) {
      setError(error.error_description || error.message);
    },
    onCompleted() {
      setSuccessMessage(true);
    },
  });

  const handleInputChange = () => {
    if (error) {
      setError('');
    }
  };

  const handleSignUp = (values) => {
    const { email, password } = values;

    registerUser({
      variables: {
        input: {
          email,
          password,
        },
      },
    });
  };

  return (
    <Registration
      loading={loading}
      handleSignUp={handleSignUp}
      handleInputChange={handleInputChange}
      successMessage={successMessage}
      error={error}
    />
  );
};

export default RegistrationContainer;
