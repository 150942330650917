import React from 'react';
import { Modal } from 'antd';

const DeleteGatewayModal = ({
  modalVisible,
  cancelModal,
  deleteGatewayHandler,
  confirmLoading,
}) => {
  return (
    <>
      <Modal
        title="Unregister Gateway"
        visible={modalVisible}
        onCancel={() => {
          cancelModal();
        }}
        confirmLoading={confirmLoading}
        okText="Unregister"
        okType="danger"
        onOk={() => {
          deleteGatewayHandler();
        }}
      >
        Are you sure you want to unregister gateway? The Gateway will need to be recommissioned and
        added to the system again once unregistered.
      </Modal>
    </>
  );
};

export default DeleteGatewayModal;
