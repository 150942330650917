import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import UserContext from '../../contexts/User';
import { GET_GATEWAYS } from '../../queries/gateways';
import { UNLINK_GATEWAY, LINK_GATEWAY, UNREGISTER_GATEWAY } from '../../mutations/gateways';

import Gateways from './component';

const GatewaysContainer = () => {
  const tenantId = useContext(UserContext);
  const [unlinkRoomModalVisible, setUnlinkRoomModalVisible] = useState(false);
  const [linkRoomModalVisible, setLinkRoomModalVisible] = useState(false);
  const [deleteGWModalVisible, setDeleteGWModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRoomIdtoLink, setSelectedRoomIdtoLink] = useState('');

  const { data: gateways, loading: gwLoading } = useQuery(GET_GATEWAYS, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
  });

  const [unlinkRoom, { unlinkLoading }] = useMutation(UNLINK_GATEWAY, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      console.log('err');
    },
    onCompleted() {
      closeUnlinkRoomModal();
    },
  });

  const [linkRoom, { linkLoading }] = useMutation(LINK_GATEWAY, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      console.log('err');
    },
    onCompleted() {
      closeLinkRoomModal();
    },
  });

  const [deleteGateway, { deleteGWLoading }] = useMutation(UNREGISTER_GATEWAY, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      console.log('err');
    },
    onCompleted() {
      closeDeleteGWModal();
    },
  });

  const handleLinkRoomSelect = (id) => {
    setSelectedRoomIdtoLink(id);
  };

  const showUnlinkRoomModal = (row) => {
    setSelectedRow(row);
    setUnlinkRoomModalVisible(true);
  };

  const closeUnlinkRoomModal = () => {
    setSelectedRow({});
    setUnlinkRoomModalVisible(false);
  };

  const showLinkRoomModal = (row) => {
    setSelectedRow(row);
    setLinkRoomModalVisible(true);
  };

  const closeLinkRoomModal = () => {
    setSelectedRow({});
    setSelectedRoomIdtoLink('');
    setLinkRoomModalVisible(false);
  };

  const showDeleteGWModal = (row) => {
    setSelectedRow(row);
    setDeleteGWModalVisible(true);
  };

  const closeDeleteGWModal = () => {
    setSelectedRow({});
    setDeleteGWModalVisible(false);
  };

  const unlinkRoomHandler = () => {
    unlinkRoom({
      variables: {
        input: {
          id: selectedRow.id,
        },
      },
    });
  };

  const linkRoomHandler = () => {
    linkRoom({
      variables: {
        input: {
          id: selectedRow.id,
          roomId: selectedRoomIdtoLink,
        },
      },
    });
  };

  const deleteGatewayHandler = () => {
    deleteGateway({
      variables: {
        input: {
          id: selectedRow.id,
        },
      },
    });
  };

  return (
    <Gateways
      gateways={gateways?.gateways}
      gwLoading={gwLoading}
      mutationLoading={unlinkLoading || deleteGWLoading || linkLoading}
      unlinkRoomModalVisible={unlinkRoomModalVisible}
      showUnlinkRoomModal={showUnlinkRoomModal}
      closeUnlinkRoomModal={closeUnlinkRoomModal}
      unlinkRoomHandler={unlinkRoomHandler}
      linkRoomModalVisible={linkRoomModalVisible}
      showLinkRoomModal={showLinkRoomModal}
      closeLinkRoomModal={closeLinkRoomModal}
      linkRoomHandler={linkRoomHandler}
      deleteGWModalVisible={deleteGWModalVisible}
      showDeleteGWModal={showDeleteGWModal}
      closeDeleteGWModal={closeDeleteGWModal}
      deleteGatewayHandler={deleteGatewayHandler}
      handleLinkRoomSelect={handleLinkRoomSelect}
      selectedRoomIdtoLink={selectedRoomIdtoLink}
    />
  );
};

export default GatewaysContainer;
