import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { message, Form } from 'antd';

import UserContext from '../../../../contexts/User';
import { HotelContext, ACTIONS } from '../../../../contexts/Hotel';
import { EDIT_ROOM, DELETE_ROOM } from '../../../../mutations/room';
import { errorMessage } from '../errorMessage';

import EditRoom from './component';

const EditRoomContainer = ({ modalVisible, cancelModal, selectedRoom }) => {
  const tenantId = useContext(UserContext);
  const { state: hotelState, dispatch } = useContext(HotelContext);
  const [form] = Form.useForm();

  const [updateRoom, { loading: updateLoading }] = useMutation(EDIT_ROOM, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      message.error(errorMessage);
    },
    onCompleted() {
      form.resetFields();
      cancelModal();
    },
  });

  const [deleteRoom, { loading: deleteLoading }] = useMutation(DELETE_ROOM, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      message.error(errorMessage);
    },
    onCompleted() {
      form.resetFields();
      cancelModal();
    },
  });

  const updateRoomHandler = ({ updatedRoom, floor: floorId }) => {
    const selectedFloor = hotelState.floors.find(floor => {
      return floor.id === floorId;
    });

    updateRoom({
      variables: {
        input: {
          floorId: floorId,
          id: selectedRoom.id,
          name: updatedRoom,
          displayOrder: selectedFloor.rooms.some((room) => room.displayOrder === selectedRoom.displayOrder)
            ? selectedFloor.rooms[selectedFloor.rooms.length - 1].displayOrder + 1
            : selectedRoom.displayOrder,
        },
      },
    });
  };

  const deleteRoomHandler = () => {
    deleteRoom({
      variables: {
        input: {
          id: selectedRoom.id,
        },
      },
    }).then(({ data }) => {
      if (data.deleteRoom.ok) {
        dispatch({
          type: ACTIONS.DELETE_ROOM,
          data: selectedRoom,
        });
      }
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedRoom, form]);

  return (
    <EditRoom
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      floors={hotelState.floors}
      onEditRoom={updateRoomHandler}
      onDeleteRoom={deleteRoomHandler}
      confirmLoading={updateLoading || deleteLoading}
      selectedRoom={selectedRoom}
      form={form}
    />
  );
};

export default EditRoomContainer;
