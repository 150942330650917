import React from 'react';
import { Modal, Form, Input, Button } from 'antd';

const EditFloor = ({
  modalVisible,
  cancelModal,
  onEditFloor,
  onDeleteFloor,
  confirmLoading,
  selectedFloor,
  form,
}) => {
  return (
    <>
      <Modal
        forceRender
        title={`Edit Floor: ${selectedFloor.name}`}
        open={modalVisible}
        onCancel={() => {
          cancelModal();
          form.resetFields();
        }}
        confirmLoading={confirmLoading}
        okText="Save"
        onOk={() => {
          form.validateFields().then((values) => {
            onEditFloor(values);
          });
        }}
      >
        <Form form={form}>
          <Form.Item
            label="Floor name"
            name="updatedFloor"
            initialValue={selectedFloor.name}
            rules={[{ required: true, message: 'Please input name of floor' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" danger onClick={onDeleteFloor}>
              Delete floor
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditFloor;
