import React, { useReducer, createContext, useMemo } from 'react';

export const HotelContext = createContext();

export const ACTIONS = {
  UPDATE_HOTELS_DATA: 'UPDATE_HOTELS_DATA',
  UPDATE_CODE: 'UPDATE_CODE',
  ADD_HOTEL: 'ADD_HOTEL',
  DELETE_HOTEL: 'DELETE_HOTEL',
  ADD_FLOOR: 'ADD_FLOOR',
  ADD_ROOM: 'ADD_ROOM',
  DELETE_FLOOR: 'DELETE_FLOOR',
  DELETE_ROOM: 'DELETE_ROOM',
};

const initialState = {
  hotels: [],
  code: '',
  floors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_HOTELS_DATA:
      return {
        hotels: action.data,
        floors: action.data[0]?.floors || [],
        code: action.data[0]?.deviceRegistrationCodes[0]?.code || '',
      };
    case ACTIONS.UPDATE_CODE:
      return {
        ...state,
        code: action.data,
      };
    case ACTIONS.ADD_HOTEL:
      return {
        ...state,
        hotels: [...state.hotels, action.data],
      };
    case ACTIONS.DELETE_HOTEL:
      return {
        hotels: [],
        code: '',
        floors: [],
      };
    case ACTIONS.ADD_FLOOR:
      return {
        ...state,
        floors: [...state.floors, action.data],
      };
    case ACTIONS.ADD_ROOM:
      return {
        ...state,
        floors: state.floors.map((floor) => {
          if (floor.id === action.data.floorId) {
            return {
              ...floor,
              rooms: [...floor.rooms, action.data],
            };
          }
          return floor;
        }),
      };
    case ACTIONS.DELETE_ROOM:
      return {
        ...state,
        floors: state.floors.map((floor) => {
          if (floor.id === action.data.floorId) {
            return {
              ...floor,
              rooms: floor.rooms.filter((room) => room.id !== action.data.id),
            };
          }
          return floor;
        }),
      };
    case ACTIONS.DELETE_FLOOR:
      return {
        ...state,
        floors: state.floors.filter((floor) => floor.id !== action.data.id),
      };
    default:
      return state;
  }
};

export const HotelProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <HotelContext.Provider value={contextValue}>{children}</HotelContext.Provider>;
};
