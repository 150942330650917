import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { setContext } from '@apollo/client/link/context';
import { AuthProvider } from './contexts/Auth';
import { HotelProvider } from './contexts/Hotel';

import App from './App';
import DemoApp from './demo/DemoApp';

import './index.scss';

const history = createBrowserHistory();

const wssEndpoint = process.env.REACT_APP_WSS_ENDPOINT || 'wss://api.lightsymphony.com/graphql';
const gqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT || 'https://api.lightsymphony.com/graphql';

const returnJWT = () => {
  const token = JSON.parse(localStorage.getItem('supabase.auth.token'))?.currentSession
    .access_token;
  return token ? `Bearer ${token}` : '';
};

const wsClient = new SubscriptionClient(wssEndpoint, {
  reconnect: true,
  connectionParams: () => ({
    authorization: returnJWT(),
  }),
});

const wsLink = new WebSocketLink(wsClient);

const httpLink = createHttpLink({
  uri: gqlEndpoint,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: returnJWT(),
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink)
);

const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

if (process.env.REACT_APP_BUILD_TARGET === 'DEMO') {
  ReactDOM.render(
    <Router history={history}>
      <DemoApp />
    </Router>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <Router history={history}>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <HotelProvider>
            <App />
          </HotelProvider>
        </AuthProvider>
      </ApolloProvider>
    </Router>,
    document.getElementById('root')
  );
}
