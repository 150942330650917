import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { message, Form } from 'antd';

import UserContext from '../../../../contexts/User';
import { HotelContext, ACTIONS } from '../../../../contexts/Hotel';
import { CREATE_FLOOR } from '../../../../mutations/floor';
import { errorMessage } from '../errorMessage';

import AddFloor from './component';

const AddFloorContainer = ({ modalVisible, cancelModal }) => {
  const tenantId = useContext(UserContext);
  const { state: hotelState, dispatch } = useContext(HotelContext);
  const [form] = Form.useForm();
  const { hotels, floors } = hotelState;

  const [createFloor, { loading }] = useMutation(CREATE_FLOOR, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      message.error(errorMessage);
    },
    onCompleted(data) {
      form.resetFields();
      dispatch({
        type: ACTIONS.ADD_FLOOR,
        data: data.createFloor.floor,
      });
      cancelModal();
    },
  });

  const createFloorHandler = ({ floor }) => {
    createFloor({
      variables: {
        input: {
          hotelId: hotels[0].id,
          name: floor,
          displayOrder: floors.length ? floors[floors.length - 1].displayOrder + 1 : 1,
        },
      },
    });
  };

  return (
    <AddFloor
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onAddFloor={createFloorHandler}
      confirmLoading={loading}
      form={form}
    />
  );
};

export default AddFloorContainer;
