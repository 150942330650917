import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ROOM_WITH_DATA } from '../../../../queries/room';
import { SEND_GATEWAY_COMMAND } from '../../../../mutations/room';
import UserContext from '../../../../contexts/User';

import RoomView from './component';

const RoomViewContainer = ({ modalVisible, cancelModal, selectedRoom }) => {
  const tenantId = useContext(UserContext);

  const { data: roomData } = useQuery(
    GET_ROOM_WITH_DATA,
    {
      context: {
        headers: {
          Tenant: tenantId,
        },
      },
      variables: {
        id: selectedRoom.id,
      },
      fetchPolicy: 'network-only',
      skip: selectedRoom.id == null,
    },
    selectedRoom
  );

  const [sendGatewayCommand, { loading: sendingCommand }] = useMutation(SEND_GATEWAY_COMMAND, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
  });

  const handleSendGatewayCommand = (command) => {
    sendGatewayCommand({
      variables: {
        input: {
          gatewayId: roomData?.room?.gateway?.id,
          command: command,
        },
      },
    });
  };

  //Check - In time: default 3pm
  //Check -out time: default 11am
  //Sum all the minutes a room is un - occupied between above two times.
  //Express as a percentage compared to total time;
  //% savings = (minutes un - occupied / total rented minutes) x 100
  //Where ‘total rented minutes’ is time between first occupancy trigger after check -in time until check -out time.

  return (
    <RoomView
      modalVisible={modalVisible}
      cancelModal={cancelModal}
      selectedRoom={selectedRoom}
      roomData={roomData}
      handleSendGatewayCommand={handleSendGatewayCommand}
      sendingCommand={sendingCommand}
    />
  );
};

export default RoomViewContainer;
