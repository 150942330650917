import React, { useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const session = supabase.auth.session();

    setUser(session?.user ?? null);
    setLoading(false);

    const { data: listener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      listener.unsubscribe();
    };
  }, []);

  const value = {
    signIn: (data) => supabase.auth.signIn(data),
    signOut: () => supabase.auth.signOut(),
    resetPassword: (data) => supabase.auth.api.resetPasswordForEmail(data),
    changePassword: (token, data) => supabase.auth.api.updateUser(token, data),
    updateEmail: (data) => supabase.auth.update(data),
    updatePassword: (data) => supabase.auth.update(data),
    user,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
