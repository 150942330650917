import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

import Login from './component';

const LoginContainer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const { signIn } = useAuth();

  const handleInputChange = () => {
    if (error) {
      setError('');
    }
  };

  const handleLogin = async (values) => {
    const { email, password } = values;

    try {
      setLoading(true);
      const { error } = await signIn({ email, password });
      if (!error) {
        history.push('/');
      } else throw error;
    } catch (error) {
      setError(error.error_description || error.message);
      setLoading(false);
    }
  };

  return (
    <Login
      loading={loading}
      handleLogin={handleLogin}
      handleInputChange={handleInputChange}
      error={error}
    />
  );
};

export default LoginContainer;
