import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';

import UserContext from '../../../../contexts/User';
import { HotelContext, ACTIONS } from '../../../../contexts/Hotel';
import { DELETE_HOTEL } from '../../../../mutations/hotel';

import DeleteHotel from './component';

const DeleteHotelContainer = ({ modalVisible, cancelModal }) => {
  const history = useHistory();
  const tenantId = useContext(UserContext);
  const { state: hotelState, dispatch } = useContext(HotelContext);

  const [deleteHotel, { loading }] = useMutation(DELETE_HOTEL, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError(error) {
      message.error(error);
    },
    onCompleted(data) {
      if (data.deleteHotel.ok) {
        cancelModal();
        history.push('/create-hotel');
        dispatch({
          type: ACTIONS.DELETE_HOTEL,
        });
      }
    },
  });

  const deleteHotelHandler = () => {
    deleteHotel({
      variables: {
        input: {
          id: hotelState.hotels[0].id,
        },
      },
    });
  };

  return (
    <DeleteHotel
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onDeleteHotel={deleteHotelHandler}
      loading={loading}
    />
  );
};

export default DeleteHotelContainer;
