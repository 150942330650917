import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { message, Form } from 'antd';

import UserContext from '../../../../contexts/User';
import { HotelContext, ACTIONS } from '../../../../contexts/Hotel';
import { CREATE_ROOM } from '../../../../mutations/room';
import { errorMessage } from '../errorMessage';

import AddRoom from './component';

const AddRoomContainer = ({ modalVisible, cancelModal, selectedFloor }) => {
  const tenantId = useContext(UserContext);
  const { dispatch } = useContext(HotelContext);
  const [form] = Form.useForm();
  const { rooms, id: floorId } = selectedFloor;

  const [createRoom, { loading }] = useMutation(CREATE_ROOM, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError() {
      message.error(errorMessage);
    },
    onCompleted(data) {
      form.resetFields();
      dispatch({
        type: ACTIONS.ADD_ROOM,
        data: data.createRoom.room,
      });
      cancelModal();
    },
  });

  const createRoomHandler = (newRoom) => {
    createRoom({
      variables: {
        input: {
          floorId: floorId,
          name: newRoom.room,
          displayOrder: rooms.length ? rooms[rooms.length - 1].displayOrder + 1 : 1,
        },
      },
    });
  };

  return (
    <AddRoom
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onAddRoom={createRoomHandler}
      confirmLoading={loading}
      form={form}
    />
  );
};

export default AddRoomContainer;
