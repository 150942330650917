import React, { useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSubscription } from '@apollo/client';

import EditDashboradContext from '../../contexts/EditDashboard';
import { HotelContext } from '../../contexts/Hotel';
import { SUBSCRIBE_ROOM_UPDATE } from '../../subscriptions/room';

import Dashboard from './component';
import Loading from '../../components/Loading/component';
import { useEffect } from 'react';

const DashboardContainer = () => {
  const isEditHotelMode = JSON.parse(localStorage.getItem('isEditHotelMode'));
  const { state: hotelState } = useContext(HotelContext);
  const history = useHistory();
  const [isEditDashboard, setEditDashboard] = useState(isEditHotelMode.mode);
  const [loading, setLoading] = useState(true);

  const [addFloorModalVisible, setAddFloorModalVisible] = useState(false);
  const [editFloorModalVisible, setEditFloorModalVisible] = useState(false);
  const [addRoomModalVisible, setAddRoomModalVisible] = useState(false);
  const [editRoomModalVisible, setEditRoomModalVisible] = useState(false);
  const [roomViewModalVisible, setRoomViewModalVisible] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState({});
  const [selectedRoom, setSelectedRoom] = useState({});

  useSubscription(SUBSCRIBE_ROOM_UPDATE, {
    variables: { hotelId: hotelState.hotels.length ? hotelState.hotels[0].id : '' },
  });

  useEffect(() => {
    if (hotelState) {
      if (!hotelState.hotels.length) {
        history.push('/create-hotel');
      } else {
        setLoading(false);
      }
    }
  }, [hotelState, history]);

  const changeDashboardMode = () => {
    localStorage.setItem('isEditHotelMode', JSON.stringify({ mode: false }));
    setEditDashboard(!isEditDashboard);
  };

  const showAddFloorModal = () => {
    setAddFloorModalVisible(true);
  };

  const showEditFloorModal = (floor) => {
    setSelectedFloor(floor);
    setEditFloorModalVisible(true);
  };

  const showAddRoomModal = (floor) => {
    setSelectedFloor(floor);
    setAddRoomModalVisible(true);
  };

  const showEditorViewRoomModal = (room) => {
    setSelectedRoom(room);
    if (isEditDashboard) {
      setEditRoomModalVisible(true);
    } else {
      setRoomViewModalVisible(true);
    }
  };

  const cancelAddFloorModal = useCallback(() => {
    setAddFloorModalVisible(false);
  }, []);

  const cancelEditFloorModal = useCallback(() => {
    setEditFloorModalVisible(false);
    setSelectedFloor({});
  }, []);

  const cancelAddRoomModal = useCallback(() => {
    setAddRoomModalVisible(false);
    setSelectedFloor({});
  }, []);

  const cancelEditRoomModal = useCallback(() => {
    setEditRoomModalVisible(false);
    setSelectedRoom({});
  }, []);

  const cancelRoomViewModal = useCallback(() => {
    setRoomViewModalVisible(false);
    setSelectedRoom({});
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <EditDashboradContext.Provider value={isEditDashboard}>
      <Dashboard
        hotelName={hotelState.hotels[0].name}
        floors={hotelState.floors}
        isEditDashboard={isEditDashboard}
        changeDashboardMode={changeDashboardMode}
        addFloorModalVisible={addFloorModalVisible}
        editFloorModalVisible={editFloorModalVisible}
        addRoomModalVisible={addRoomModalVisible}
        editRoomModalVisible={editRoomModalVisible}
        roomViewModalVisible={roomViewModalVisible}
        showAddFloorModal={showAddFloorModal}
        showEditFloorModal={showEditFloorModal}
        showAddRoomModal={showAddRoomModal}
        showEditorViewRoomModal={showEditorViewRoomModal}
        cancelAddFloorModal={cancelAddFloorModal}
        cancelEditFloorModal={cancelEditFloorModal}
        cancelAddRoomModal={cancelAddRoomModal}
        cancelEditRoomModal={cancelEditRoomModal}
        cancelRoomViewModal={cancelRoomViewModal}
        selectedFloor={selectedFloor}
        selectedRoom={selectedRoom}
      />
    </EditDashboradContext.Provider>
  );
};

export default DashboardContainer;
