import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import UserContext from '../../contexts/User';
import { HotelContext, ACTIONS } from '../../contexts/Hotel';
import { CREATE_HOTEL } from '../../mutations/hotel';

import CreateHotel from './component';

const CreateHotelContainer = () => {
  const tenantId = useContext(UserContext);
  const { dispatch } = useContext(HotelContext);
  const history = useHistory();

  const [createHotel, { loading }] = useMutation(CREATE_HOTEL, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError(error) {
      message.error(error.message);
    },
    onCompleted(data) {
      dispatch({
        type: ACTIONS.ADD_HOTEL,
        data: data.createHotel.hotel,
      });
      history.push('/dashboard');
    },
  });

  const createHotelHandler = (data) => {
    const { hotelName } = data;

    createHotel({
      variables: {
        input: {
          name: hotelName,
        },
      },
    });
  };

  return <CreateHotel createHotel={createHotelHandler} loading={loading} />;
};

export default CreateHotelContainer;
