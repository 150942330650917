import React, { useContext } from 'react';
import { Row, Col, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import EditDashboradContext from '../../contexts/EditDashboard';

import Room from '../../components/Room/component';

import './component.scss';

const { Paragraph, Text } = Typography;

const Floor = ({ floor, showEditFloorModal, showAddRoomModal, showEditorViewRoomModal }) => {
  const isEditDashboard = useContext(EditDashboradContext);
  const rooms = [...floor.rooms].sort((a, b) => a.displayOrder - b.displayOrder);

  return (
    <>
      <Row className="floor">
        <Col
          xs={3}
          sm={1}
          xl={1}
          className="floor-header"
          onClick={isEditDashboard ? () => showEditFloorModal(floor) : undefined}
        >
          <Paragraph>{floor.name}</Paragraph>
        </Col>
        <Col xs={21} sm={23} xl={23} className="floor-rooms">
          <ul>
            {rooms.length !== 0 &&
              rooms.map((room) => {
                  return (
                    <Room
                      room={room}
                      key={room.id}
                      showEditorViewRoomModal={showEditorViewRoomModal}
                    />
                  );
                })}
            {isEditDashboard && (
              <li className="add-room" onClick={() => showAddRoomModal(floor)}>
                <div>
                  <PlusOutlined />
                </div>
              </li>
            )}
            {!floor.rooms.length && !isEditDashboard && (
              <Text>
                You don't have added rooms. Please switch to edit mode on 'Hotel Settings' page and
                add them
              </Text>
            )}
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default Floor;
