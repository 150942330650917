import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { HotelContext, ACTIONS } from '../../contexts/Hotel';
import UserContext from '../../contexts/User';
import { UPDATE_HOTEL, ENABLE_REG_CODE, DISABLE_REG_CODE } from '../../mutations/hotel';

import HotelSettings from './component';

const HotelSettingsContainer = () => {
  const history = useHistory();
  const tenantId = useContext(UserContext);
  const { state: hotelState, dispatch } = useContext(HotelContext);
  const [code, setCode] = useState(hotelState.code);
  const [deleteHotelModalVisible, setDeleteHotelModalVisible] = useState(false);

  const [updateHotel, { loading: updatingHotelData }] = useMutation(UPDATE_HOTEL, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError(error) {
      message.error(error.message);
    },
  });

  const [enableRegCode, { loading: enableCodeLoading }] = useMutation(ENABLE_REG_CODE, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError(error) {
      message.error(error.message);
    },
    onCompleted(data) {
      setCode(data.createRegistrationCode.code);
      dispatch({
        type: ACTIONS.UPDATE_CODE,
        data: data.createRegistrationCode.code,
      });
    },
  });

  const [disableRegCode, { loading: disableCodeLoading }] = useMutation(DISABLE_REG_CODE, {
    context: {
      headers: {
        Tenant: tenantId,
      },
    },
    onError(error) {
      message.error(error.message);
    },
    onCompleted(data) {
      if (data.deleteRegistrationCode.ok) {
        setCode('');
        dispatch({
          type: ACTIONS.UPDATE_CODE,
          data: '',
        });
      }
    },
  });

  const handleEditHotelClick = () => {
    localStorage.setItem('isEditHotelMode', JSON.stringify({ mode: true }));
    history.push('/');
  };

  const handleEnableRegClick = () => {
    enableRegCode({
      variables: {
        input: {
          hotelId: hotelState.hotels[0].id,
        },
      },
    });
  };

  const handleDisableRegClick = () => {
    disableRegCode({
      variables: {
        input: {
          code: code,
        },
      },
    });
  };

  const updateHotelData = (data) => {
    const { hotelName } = data;

    updateHotel({
      variables: {
        input: {
          name: hotelName,
          id: hotelState.hotels[0].id,
        },
      },
    });
  };

  const showDeleteHotelModal = () => {
    setDeleteHotelModalVisible(true);
  };

  const cancelDeleteHotelModal = useCallback(() => {
    setDeleteHotelModalVisible(false);
  }, []);

  return (
    <HotelSettings
      hotel={hotelState.hotels[0]}
      code={code}
      updateHotelData={updateHotelData}
      handleEditHotelClick={handleEditHotelClick}
      handleEnableRegClick={handleEnableRegClick}
      handleDisableRegClick={handleDisableRegClick}
      deleteHotelModalVisible={deleteHotelModalVisible}
      cancelDeleteHotelModal={cancelDeleteHotelModal}
      showDeleteHotelModal={showDeleteHotelModal}
      loading={updatingHotelData || enableCodeLoading || disableCodeLoading}
    />
  );
};

export default HotelSettingsContainer;
