import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { useAuth } from '../../contexts/Auth';

import ChangePassword from './component';

const ChangePasswordContainer = () => {
  const [loading, setLoading] = useState(false);
  const { changePassword } = useAuth();
  const history = useHistory();

  const handleChangePassword = async (values) => {
    const token = JSON.parse(localStorage.getItem('supabase.auth.token'));

    try {
      setLoading(true);
      const { error } = await changePassword(token.currentSession.access_token, {
        password: values.password,
      });

      if (!error) {
        setLoading(false);
        message.success('Password changed successfully!');
        history.push('/');
      } else throw error;
    } catch (error) {
      message.error(error.error_description || error.message);
      setLoading(false);
    }
  };

  return <ChangePassword loading={loading} handleChangePassword={handleChangePassword} />;
};

export default ChangePasswordContainer;
