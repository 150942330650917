import React, { useState, useCallback } from 'react';
import { useAuth } from '../../contexts/Auth';
import { message } from 'antd';

import AccountSettings from './component';

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const AccountSettingsContainer = () => {
  const { updateEmail, updatePassword } = useAuth();
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [emailUpdating, setEmailUpdating] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passUpdating, setPassUpdating] = useState(false);
  const [passError, setPassError] = useState(false);

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setPassError(false);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const handleEmailSave = async () => {
    if (email && validateEmail(email)) {
      try {
        setEmailUpdating(true);
        const { error } = await updateEmail({ email });

        if (!error) {
          message.info('Confirmation sent to new email!');
          setEmailUpdating(false);
        } else throw error;
      } catch (error) {
        console.error(error.error_description || error.message);
        setEmailUpdating(false);
      }
    } else {
      setEmailError(true);
    }
  };

  const handlePassSave = async () => {
    if (password) {
      try {
        setPassUpdating(true);
        const { error } = await updatePassword({ password });

        if (!error) {
          message.info('Password has been changed!');
          setPassUpdating(false);
          setPassword('');
        } else throw error;
      } catch (error) {
        console.error(error.error_description || error.message);
        setPassUpdating(false);
      }
    } else {
      setPassError(true);
    }
  };

  const showDeleteAccountModal = () => {
    setDeleteAccountModalVisible(true);
  };

  const cancelDeleteAccountModal = useCallback(() => {
    setDeleteAccountModalVisible(false);
  }, []);

  return (
    <AccountSettings
      deleteAccountModalVisible={deleteAccountModalVisible}
      cancelDeleteAccountModal={cancelDeleteAccountModal}
      showDeleteAccountModal={showDeleteAccountModal}
      password={password}
      passUpdating={passUpdating}
      passError={passError}
      handleChangePassword={handleChangePassword}
      email={email}
      emailUpdating={emailUpdating}
      emailError={emailError}
      handleChangeEmail={handleChangeEmail}
      handleEmailSave={handleEmailSave}
      handlePassSave={handlePassSave}
    />
  );
};

export default AccountSettingsContainer;
