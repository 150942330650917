import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './component.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;

const Loading = () => {
  return (
    <div className="loading">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loading;
